import { Form } from "../../components/Form";

export default function Anexos() {
  return (
    <>
      <div className="flex flex-col">
        <Form.Field className="mb-5">
          <Form.Label htmlFor="Documento">Ato de Nomeação</Form.Label>
          <Form.Input id="Documento" name="Documento" type="file" />
          <Form.ErrorMessage field="Documento" />
        </Form.Field>
        <Form.Field>
          <Form.Label htmlFor="Foto">Foto</Form.Label>
          <Form.Input id="Foto" name="Foto" type="file" />
          <Form.ErrorMessage field="Foto" />
        </Form.Field>
      </div>
    </>
  )
}