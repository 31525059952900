import { Form } from "../../components/Form";

export default function DadosPessoais() {
  return (
    <>
      <Form.Field>
        <Form.Label htmlFor="Data_Nascimento">Data de Nascimento</Form.Label>
        <Form.Input id="Data_Nascimento" name="Data_Nascimento" type="date" />
        <Form.ErrorMessage field="Data_Nascimento" />
      </Form.Field>
      <Form.Field>
        <Form.Label htmlFor="Mae">Nome da Mãe</Form.Label>
        <Form.Input id="Mae" name="Mae" type="text" />
        <Form.ErrorMessage field="Mae" />
      </Form.Field>
      <Form.Field>
        <Form.Label htmlFor="Pai">Nome do Pai</Form.Label>
        <Form.Input id="Pai" name="Pai" type="text" />
        <Form.ErrorMessage field="Pai" />
      </Form.Field>
      <Form.Field>
        <Form.Label htmlFor="CPF">CPF</Form.Label>
        <Form.Input id="CPF" name="CPF" type="text" />
        <Form.ErrorMessage field="CPF" />
      </Form.Field>
      <Form.Field>
        <Form.Label htmlFor="RG">RG</Form.Label>
        <Form.Input id="RG" name="RG" type="text" />
        <Form.ErrorMessage field="RG" />
      </Form.Field>
      <Form.Field>
        <Form.Label htmlFor="Orgao_Expedidor">Orgão Expedidor</Form.Label>
        <Form.Input id="Orgao_Expedidor" name="Orgao_Expedidor" type="text" />
        <Form.ErrorMessage field="Orgao_Expedidor" />
      </Form.Field>
      <Form.Field>
        <Form.Label htmlFor="Data_Expedicao">Data de Expedição</Form.Label>
        <Form.Input id="Data_Expedicao" name="Data_Expedicao" type="date" />
        <Form.ErrorMessage field="Data_Expedicao" />
      </Form.Field>
      <Form.Field>
        <Form.Label htmlFor="Naturalidade">Naturalidade</Form.Label>
        <Form.Input id="Naturalidade" name="Naturalidade" type="text" />
        <Form.ErrorMessage field="Naturalidade" />
      </Form.Field>
      <Form.Field>
        <Form.Label htmlFor="Nacionalidade">Nacionalidade</Form.Label>
        <Form.Input id="Nacionalidade" name="Nacionalidade" type="text" />
        <Form.ErrorMessage field="Nacionalidade" />
      </Form.Field>
      <Form.Field>
        <Form.Label htmlFor="Email">Email</Form.Label>
        <Form.Input id="Email" name="Email" type="text" />
        <Form.ErrorMessage field="Email" />
      </Form.Field>
      <Form.Field>
        <Form.Label htmlFor="Celular">Celular</Form.Label>
        <Form.Input id="Celular" name="Celular" type="text" />
        <Form.ErrorMessage field="Celular" />
      </Form.Field>
      <Form.Field>
        <Form.Label htmlFor="Telefone">Telefone</Form.Label>
        <Form.Input id="Telefone" name="Telefone" type="text" />
        <Form.ErrorMessage field="Telefone" />
      </Form.Field>
    </>
  )
}