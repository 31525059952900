import React from 'react'

import TabsProvider from './TabsContext'

type TabsProps = {
  children: React.ReactNode
}

export default function Tabs({ children }: TabsProps) {
  return <TabsProvider>{children}</TabsProvider>
}
