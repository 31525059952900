import classNames from "classnames";

export default function SearchBar({ className, onChange }: { className?: string, onChange?: (value: string) => void}) {
  return (
    <>
      <div className={classNames("flex justify-center items-center p-2 relative", className)}>
        <input
          type="search"
          placeholder="Buscar por nome"
          className="search-input"
            onChange={(event) => onChange && onChange(event.target.value)}
        />
      </div>
    </>
  );
}
