import { MemberInfoProps } from ".";
import avatar from "../../assets/avatar.jpg";

export default function MemberItem(
  { 
    member,
    selectedTab,
    handleEditAction,
    handleRegistrationAction,
    handleInactivateAction,
    handleActivateAction,
    handleGetDocumentAction
  }: { member: MemberInfoProps, selectedTab: string, handleEditAction: (member: number) => void, handleRegistrationAction: (member: number) => void, handleInactivateAction: (member: number) => void, handleActivateAction: (member: number) => void, handleGetDocumentAction: (member: number) => void}) {
  let forma = ''
  if (member.Forma_Pagamento === 1) {
    forma = 'Desconto no Contracheque';
  }
  else {
    forma = 'Em Espécie';
  }

  return (
    <>
      <div className="flex member-item justify-between">
        <div className="flex">
          <div className=" mr-5">
            <img src={avatar} alt="exemplo" className="userimage" />
          </div>
          <div className="flex flex-col">
            <p className="text-red-600 text-sm font-light	">{member.Matricula || member.Matricula_Tjam}</p>
            <p className="font-bold	text-base mb-2">{member.Nome}</p>
            <div className="flex text-sm font-light text-gray-600">
              <p className="mr-4">Admissão: {new Date(member.Data_Cadastro || member.Data_Sindicalizacao).toLocaleDateString()}</p>
              {
                member.Ultima_Desfiliacao ? <p className="text-red-600">Última Desfiliação: {new Date(member.Ultima_Desfiliacao).toLocaleDateString()}</p> :null
              }
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <h3 className="label text-center">Data de Nascimento</h3>
          <p className="text-center">{new Date(member.Data_Nascimento).toLocaleDateString()}</p>
        </div>
        <div className="flex flex-col">
          <h3 className="label text-center">Tipo de Pagamento</h3>
          <p className="text-center">{forma}</p>
        </div>
        <div className="actions">
          <div className="flex justify-between">
            <button className="action register mr-4" onClick={() => handleEditAction(member.idSindicalizado || member.idSindicalizadoPensionista)}>Editar</button>
            <button className="action register mr-4" onClick={() => handleRegistrationAction(member.idSindicalizado || member.idSindicalizadoPensionista)}>Gerar Ficha PDF</button>
            {
              selectedTab.includes('sind') ? (
                <button className="action register mr-4" onClick={() => handleGetDocumentAction(member.idSindicalizado || member.idSindicalizadoPensionista)}>Download Anexo</button>
              ): <></>
            }
            {
              selectedTab === 'sind_ativo' || selectedTab === 'pen_ativo' ? (
                <button className="action inactivate" onClick={() => handleInactivateAction(member.idSindicalizado || member.idSindicalizadoPensionista)}>Inativar</button>
              ) : 
                <button className="action activate" onClick={() => handleActivateAction(member.idSindicalizado || member.idSindicalizadoPensionista)}>Ativar</button>
            }
          </div>
        </div>
      </div>
    </>
  )
}