import * as html2pdf from 'html2pdf.js';

type MemberInfoProps = {
  CPF: string;
  Data_Nascimento: Date | string;
  Nome: string;
  idSindicalizadoPensionista: number;
  Data_Falecimento_Sindicalizado: Date;
  Nome_Sindicalizado: string;
  CPF_Sindicalizado: string;
  Data_Sindicalizacao: Date;
  Status_Tjam: string | number;
  Status_Sintjam: string | number;
  Endereco: string;
  Matricula_Sintjam: string;
  Matricula_Tjam: string;
  Ultima_Desfiliacao: Date | string | null;
  Forma_Pagamento: number;
  Celular: string;
};

export function generateRegisterPDFPensionista(values: MemberInfoProps) {
    let tjam = '';
    let sint = '';
    let forma = '';
    let dataAdmissao = '';
    let dataNascimento = '';
    let dataCadastro = '';
    let ultimaDesfilizacao = values.Ultima_Desfiliacao ? new Date(values.Ultima_Desfiliacao).toLocaleDateString('pt-BR') : '';


    if (values.Status_Tjam === 1) {
        tjam = 'Ativo';
    } else if(values.Status_Tjam === 2) {
        tjam = 'Inativo';
    }

    if (values.Status_Sintjam === 1) {
        sint = 'Ativo';
    } else if (values.Status_Sintjam === 2) {
        sint = 'Inativo';
    }

    if (values.Forma_Pagamento === 1) {
        forma = 'Desconto no Contracheque';
    }
    else {
        forma = 'Em Espécie';
    }

    if (values.Data_Nascimento) {
        dataNascimento = new Date(values.Data_Nascimento).toLocaleDateString('pt-BR');
    }

    if (values.Data_Sindicalizacao) {
      dataCadastro = new Date(values.Data_Sindicalizacao).toLocaleDateString('pt-BR');
  }


  // Definir a string HTML que você deseja incluir no PDF
  const htmlString = `
  <html class="wb">
  <style>
        body {
            font-family: Arial !important;
            font-size: 12px;
        }

        .navbar-fixed {
            background: #950000;
        }

        .mt-4 {
            margin-top: 4rem;
        }

        .mb-2 {
            margin-bottom: 2rem;
        }

        .pb-2 {
            padding-bottom: 2rem;
        }

        .text-center {
            text-align: center;
        }

        .border {
            border: 1px solid #000;
        }

        .table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 0 !important;
        }

        .col-12 {
            width: 100%;
            position: relative;
            min-height: 1px;
            padding-right: 15px;
            padding-left: 15px;
        }

        .table-paragraph {
            border-collapse: collapse;
            width: 100%;
        }

        .p-3 {
            padding: 1rem !important;
        }
        .wb-rel { font-family: Arial !important; }
        .navbar-fixed { width: 100%; height: 50px; background: #950000; }
        .wb-rel-header { position: relative; }
        .wb-rel-header img.left { margin-left: 10px; margin-top: 10px; }
        .wb-rel-header img { display: inline-block; position: absolute; float: right; }
        .center { text-align: center; }
        .sitacao-rel { margin-bottom: 0 !important; }
        .sitacao-rel p { border: .3px solid #000; background-color: #d2d2d2; color: #000; }
        .sitacao-rel table { border-collapse: collapse; width: 100%; }
        .sitacao-rel table td { padding: 2px; border: 0.5px solid #000; }
        .clearfix::after { content: ''; clear: both; display: table; margin-top: 10px }"
    </style>
  <body style="font-family: Arial !important">
      <div class="wb-rel" style="">
      <div class="navbar-fixed">
              <nav style="background: #950000">
              </nav>
      </div>
          <div class="row">
              <div class="wb-rel-header">
              </div>
              <div class="col l12 center">
                <h4 style="position: absolute;margin-top: -50px; width: 100%; color: white; font-size: 18px">Ficha Sindical</h4>
              </div>
              <div class="wb-sitacao-rel">
                  <div class="wb-rel-title col l12" >
                      <p class="center" style="border: .3px solid #000;background-color: #d2d2d2;color: #000;padding-bottom: 5px !important;">Situação</p>
                      <p style="padding: 2px;padding-bottom: 5px;border:0.2px solid #000"><b>TJAM:</b> ${tjam}</p>
                      <p style="padding: 2px;padding-bottom: 5px;border:0.2px solid #000"><b>SINTJAM:</b>${sint}</p>
                   </div>
              </div>
              <div class="clearfix"></div>
              <div class="col l12">
                   <p class="center" style="border: .3px solid #000;background-color: #d2d2d2;color: #000;padding-bottom: 5px !important;">Dados Sindicais</p>
                   <p style="padding: 2px;padding-bottom: 5px;border:0.2px solid #000"><b>Nome:</b> ${values.Nome}</p>
                   <table style="padding:2px;border:0.7px solid #000; width: 100%">
                      <tr>
                          <td style="padding:2px 2px 7px 10px;border-right:.5px solid #000" width="20">Matrícula Sintjam:</td>
                          <td style="padding:2px 2px 7px 10px">${values.Matricula_Sintjam}</td>
                          <td style="padding:2px 2px 7px 2px;border-left:.5px solid #000;border-right:.5px solid #000" width="10">Matricula Tjam:</td>
                          <td style="padding:2px 2px 7px 10px;">${values.Matricula_Tjam}</td>
                       </tr>
                   </table>
                   <table style="padding:2px;border:0.5px solid #000; width: 100%">
                      <tr>
                          <td style="padding:2px 2px 7px 2px;border-left:.5px solid #000;border-right:.5px solid #000" width="151">Forma de Pagamento:</td>
                          <td style="padding:2px 2px 7px 10px;">${forma}</td>
                      </tr>
                   </table>
                   <table style="padding:2px;border:0.5px solid #000; width: 100%">
                      <tr>
                          <td style="padding:2px 2px 5px 2px;border-left:.5px solid #000;border-right:.5px solid #000" width="131">Data de Admissão:</td>
                          <td style="padding:2px 2px 5px 10px;" width="170">${dataAdmissao !== "" ? dataAdmissao : "Não informado"}</td>
                          <td style="padding:2px 2px 5px 2px;border-left:.5px solid #000;border-right:.5px solid #000" width="131">Data de Sindicalização:</td>
                          <td style="padding:2px 2px 5px 10px;" width="170">${dataCadastro}</td>
                          <td style="padding:2px 2px 5px 2px;border-left:.5px solid #000;border-right:.5px solid #000" width="131">Ultima Desfiliação:</td>
                          <td style="padding:2px 2px 5px 10px;" width="170">${ultimaDesfilizacao}</td>
                      </tr>
                   </table>
              </div>
               <div class="clearfix"></div>
               <div class="col l12">
                  <p class="center" style="border: .3px solid #000;background-color: #d2d2d2;color: #000;padding-bottom: 5px !important;">Dados Pessoais</p>
                  <table style="padding:2px;border:0.5px solid #000; width: 100%">
                      <tr>
                          <td style="padding:2px 2px 5px 2px;border-left:.5px solid #000;border-right:.5px solid #000" width="151">Data de Nascimento:</td>
                          <td style="padding:2px 2px 5px 10px;" width="170">${dataNascimento}</td>
                          <td style="padding:2px 2px 5px 2px;border-left:.5px solid #000;border-right:.5px solid #000" width="30">CPF:</td>
                          <td style="padding:2px 2px 5px 10px;">${values.CPF}</td>
                      </tr>
                   </table>
                   <table style="padding:2px;border:0.5px solid #000; width: 100%">
                     <tr>
                              <td style="padding:2px 2px 5px 2px;border-left:.5px solid #000;border-right:.5px solid #000" width="30">Logradouro:</td>
                              <td style="padding:2px 2px 5px 10px;" width="170">${values.Endereco !== "" ? values.Endereco : "Não Informado"}</td>
                      </tr>
                   </table>
               </div>
               <div class="clearfix"></div>
               <div class="col l12">
                   <p class="center" style="border: .3px solid #000;background-color: #d2d2d2;color: #000;padding-bottom: 5px !important;">Contato</p>
                   <table style="padding:2px;border:0.5px solid #000; width: 100%">
                      <tr>
                          <td style="padding:2px 2px 5px 2px;border-left:.5px solid #000;border-right:.5px solid #000" width="131">Celular:</td>
                          <td style="padding:2px 2px 5px 2px;">${values.Celular !== "" ? values.Celular : "Não Informado"}</td>
                      </tr>
                   </table>
              </div>
  
          </div>
  
      </div>
  
  
  </body>
  </html>
  `;

    // Configurações para html2pdf
    const options = {
        margin: 10,
        filename: 'documento.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };


    html2pdf().from(htmlString).set(options).save();
}
