import { Routes, Route, Outlet, Link } from "react-router-dom";

import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Home from "./pages/Home";
import Create from "./pages/Create";
import Report from "./pages/Report";
import Update from "./pages/Update";
import SignIn from "./pages/Signin";
import Pensionistas from "./pages/Pensionistas";
import PensionistasUpdate from "./pages/Pensionistas/Update";

interface ContainerProps {
  Content: JSX.Element;
}

function Container({ Content }: ContainerProps) {
  return (
    <div className="max-h-screen flex flex-col">
        <Navbar />
        <div className="grid grid-cols-[auto,1fr] flex-grow-1 overflow-auto">
          <Sidebar />
          <div className="overflow-x-hidden pt-4 pl-4 pb-16">
            {Content}
          </div>
        </div>
      </div>
  )
}

export default function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/home" element={<Container Content={<Home />} />} />
        <Route path="/create" element={<Container Content={<Create />} />} />
        <Route path="/update/:id" element={<Container Content={<Update />} />} />
        <Route path="/report" element={<Container Content={<Report />} />} />
        <Route path="/pensionista" element={<Container Content={<Pensionistas/>}/>}/>
        <Route path="/pensionista/:id" element={<Container Content={<PensionistasUpdate/>}/>}/>
      </Routes>
    </>
  );
}
