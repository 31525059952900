import { z } from "zod";


export const createSignInSchema = z.object({
  username: z.string({
    required_error: 'Usuário Precisa ser preenchido',
  }),
  password: z.string({
    required_error: 'Senha Precisa ser preenchida',
  })
})