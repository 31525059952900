import { z } from "zod";
import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useFieldArray, useForm } from "react-hook-form";

import { updateSindicalizadoSchema  } from './form_schema';
import { useEffect, useState } from "react";
import { Tabs } from "../../components/Tabs";
import DadosSindicais from "./DadosSindicais";
import DadosPessoais from "./DadosPessoais";
import Localizacao from "./Localização";
import Dependentes from "./Dependentes";
import Pensionista from "./Pensionista";
import Anexos from "./Anexos";
import { Card } from "../../components/Card";
import { useAuth, useAxios } from "../../hooks";
import axios from "axios";
import Modal from "../../components/Modal";
import { useNavigate, useParams } from "react-router-dom";
import { Config } from "../../config";
import { generateMatricula } from "../../helpers/Matricula";

type UpdateSindicalizadoData = z.infer<typeof updateSindicalizadoSchema>;

export default function Update() {
  const { token } = useAuth();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [modal, setModal] = useState({message: '', type: '', active: false});
  const {
    response: getDataResponse,
    loading: getDataLoading,
    error: getDataError,
  } = useAxios({
    url: Config.API.Classe,
    method: "GET",
    token,
  });

  const {
    response: getDataResponseSind,
    loading: getDataLoadingSind,
    error: getDataErrorSind,
  } = useAxios({
    url: `${Config.API.Sindicalizados}/${id}`,
    method: "GET",
    token,
  });

  useEffect(() => {
    if (modal.active) {
      setTimeout(() => {
        setModal({message: '', type: '', active: false});
      }, 3000);
    }
  }, [modal])


  const updateUserForm = useForm<UpdateSindicalizadoData>({
    resolver: zodResolver(updateSindicalizadoSchema),
  })

  useEffect(() => {
    setValue('Nome', getDataResponseSind?.data?.Nome);
    setValue('Matricula', getDataResponseSind?.data?.Matricula);
    if(getDataResponseSind?.data?.Matricula_Sint) {
      setValue('Matricula_Sint', getDataResponseSind?.data?.Matricula_Sint);
    }else {
      setValue('Matricula_Sint', generateMatricula());
    }
    setValue('Data_Nascimento', getDataResponseSind?.data?.Data_Nascimento);
    setValue('Classe_idClasse', getDataResponseSind?.data?.Classe_idClasse);
    setValue('Categoria', getDataResponseSind?.data?.Categoria);
    setValue('Data_Admissao', getDataResponseSind?.data?.Data_Admissao);
    setValue('Forma_Pagamento', getDataResponseSind?.data?.Forma_Pagamento);
    setValue('Status', getDataResponseSind?.data?.Status);
    setValue('Status_Sint', getDataResponseSind?.data?.Status_Sint);
    setValue('Lotacao', getDataResponseSind?.data?.Lotacao);
    setValue('Local', getDataResponseSind?.data?.Local);
    setValue('Data_Nascimento', getDataResponseSind?.data?.Data_Nascimento);
    setValue('Mae', getDataResponseSind?.data?.Mae);
    setValue('Pai', getDataResponseSind?.data?.Pai);
    setValue('CPF', getDataResponseSind?.data?.CPF);
    setValue('RG', getDataResponseSind?.data?.RG);
    setValue('Orgao', getDataResponseSind?.data?.Orgao);
    setValue('Data_Expedicao', getDataResponseSind?.data?.Data_Expedicao);
    setValue('Naturalidade', getDataResponseSind?.data?.Naturalidade);
    setValue('Nacionalidade', getDataResponseSind?.data?.Nacionalidade);
    setValue('Email', getDataResponseSind?.data?.Email);
    setValue('Celular', getDataResponseSind?.data?.Celular);
    setValue('Telefone', getDataResponseSind?.data?.Telefone);
    setValue('CEP', getDataResponseSind?.data?.CEP);
    setValue('Estado', getDataResponseSind?.data?.Estado);
    setValue('Cidade', getDataResponseSind?.data?.Cidade);
    setValue('Logradouro', getDataResponseSind?.data?.Logradouro);
    setValue('Bairro', getDataResponseSind?.data?.Bairro);
    if (getDataResponseSind?.data?.Dependentes.length > 0) {
      const dependentes = getDataResponseSind?.data?.Dependentes;
      dependentes.map((dependente: any, index: number) => {
        setValue(`Dependentes.${index}.Nome`, getDataResponseSind?.data?.Dependentes[index].Nome);
        setValue(`Dependentes.${index}.Data_Nascimento`, getDataResponseSind?.data?.Dependentes[index].Data_Nascimento);
        setValue(`Dependentes.${index}.CPF`, getDataResponseSind?.data?.Dependentes[index].CPF);
        setValue(`Dependentes.${index}.Sexo`, getDataResponseSind?.data?.Dependentes[index].Sexo);
      })
    }
    setValue('Pencionistas.Nome', getDataResponseSind?.data?.Pencionista?.Nome);
    setValue('Pencionistas.Data_Nascimento', getDataResponseSind?.data?.Pencionista?.Data_Nascimento);
    setValue('Pencionistas.CPF', getDataResponseSind?.data?.Pencionista?.CPF);
  }, [getDataResponseSind])

  const { 
    handleSubmit, 
    formState: { isSubmitting, errors }, 
    watch,
    control,
    setValue,
  } = updateUserForm;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'Dependentes',
  });

  async function updateUser(data: UpdateSindicalizadoData) {
    try {
      const resp = await axios.put(`${Config.API.Sindicalizados}/${id}`, data, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })

      const idSindicalizado = 1023;
        if (data?.Documento) {
          const formData = new FormData();
          formData.append('file', data.Documento[0]);
          formData.append('idSindicalizado', String(idSindicalizado));

          await axios.post(Config.API.Sindicalizados, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${token}`,
            },
          }).then((response) => {
            setModal({message: 'Successo ao atualizar', type: 'success', active: true});
          }).catch(errors => {
            console.log(errors)
            setModal({message: 'Erro ao atualizar', type: 'error', active: true});
          });
        }else if (data?.Foto) {
          const formData = new FormData();
          formData.append('file', data.Foto[0]);
          formData.append('idSindicalizado', String(idSindicalizado));

          await axios.post(Config.API.Sindicalizados, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${token}`,
            },
          }).then((response) => { 
          }).catch(errors => {
            setModal({message: 'Erro ao enviar o arquivo mas o sindicalizado foi salvo com sucesso', type: 'error', active: true});
          });
        }

      if (resp.status < 300) {
        setModal({message: 'Successo ao atualizar', type: 'success', active: true});
        setTimeout(() => {
          navigate(`/home`);
        }, 3000);
      }
        
    } catch (error) {
      console.log(error)
      setModal({message: 'Erro ao atualizar', type: 'error', active: true});
    }
    
  }
  

  const tabs = [
    { id: 'tab1_id', title: 'Dados Sindicais', content: <DadosSindicais classes={getDataResponse?.data} /> },
    { id: 'tab2_id', title: 'Dados Pessoais', content: <DadosPessoais /> },
    { id: 'tab3_id', title: 'Localização', content: <Localizacao /> },
    { id: 'tab4_id', title: 'Dependentes', content: <Dependentes fields={fields} append={append} remove={remove} /> },
    { id: 'tab5_id', title: 'Pensionistas', content: <Pensionista /> },
    { id: 'tab6_id', title: 'Documentos / Anexos', content: <Anexos /> },
  ]; 


  return (
    <>
      {
        modal.active && <Modal message={modal.message} type={modal.type} />
      }
      <Card.CardContent>
        <Card.Header>
          <h1>Cadastro de Sindicalizado</h1>
        </Card.Header>
          <FormProvider {...updateUserForm}>
          
            <form 
              onSubmit={handleSubmit(updateUser)}
            >
              <Card.Body>  
                <Tabs.Tab>
                  <Tabs.Titles items={tabs.map(({ id, title }) => ({ id, title }))} />
                  <Tabs.Contents
                    items={tabs.map(({ id, content }) => ({
                      id,
                      content: <>{content}</>,
                    }))}
                  />
                </Tabs.Tab>
              </Card.Body>
              <Card.Footer>
                <button type="submit" className="submit-input">Atualizar</button>
              </Card.Footer>
            </form>
        </FormProvider>

      </Card.CardContent>
    </>
  )
}