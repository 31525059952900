import * as XLSX from 'xlsx';

type MemberInfoProps = {
  CPF: string;
  Data_Nascimento: Date | string;
  Nome: string;
  idSindicalizadoPensionista: number;
  Data_Falecimento_Sindicalizado: Date;
  Nome_Sindicalizado: string;
  CPF_Sindicalizado: string;
  Data_Sindicalizacao: Date;
  Status_Tjam: string | number;
  Status_Sintjam: string | number;
  Endereco: string;
  Matricula_Sintjam: string;
  Matricula_Tjam: string;
  Ultima_Desfiliacao: Date | string | null;
  Forma_Pagamento: number;
  Celular: string;
};

export function generateExcelPensionista(data: MemberInfoProps[]) {
  if (data.length === 0) {
    return;
  }

  const headers = [
    'Matricula',
    'Matricula Sintjam',
    'Nome Pensionista',
    'CPF',
    'Data de Nascimento',
    'Celular',
    'Endereço',
    'Forma de Pagamento',
    'Nome do Sindicalizado',
    'CPF do Sindicalizado',
    'Status TJAM',
    'Status Sintjam',
    'Data Filiação',
    'Ultima Desfiliação',
  ]

  const dataArray = data.map((member) => {
    console.log(member.Ultima_Desfiliacao)
    const modifiedDataAdmissao = new Date(member.Data_Sindicalizacao).toLocaleDateString('pt-BR');
    const dataNascimento = new Date(member.Data_Nascimento).toLocaleDateString('pt-BR');
    let ultimaDesfilizacao = member.Ultima_Desfiliacao ? new Date(member.Ultima_Desfiliacao).toLocaleDateString('pt-BR') : '';
    let tjam = '';
    let sint = '';
    let forma = '';
    let local = '';

    if (member.Status_Tjam === 1) {
      tjam = 'Ativo';
    } else if(member.Status_Tjam === 2) {
        tjam = 'Inativo';
    }

    if (member.Status_Sintjam === 1) {
        sint = 'Ativo';
    } else if (member.Status_Sintjam === 2) {
        sint = 'Inativo';
    }

    if (member.Forma_Pagamento === 1) {
        forma = 'Desconto no Contracheque';
    }
    else {
        forma = 'Em Espécie';
    }

    return [
      member.Matricula_Tjam,
      member.Matricula_Sintjam,
      member.Nome,
      member.CPF,
      dataNascimento,
      member.Celular,
      member.Endereco,
      forma,
      member.Nome_Sindicalizado,
      member.CPF_Sindicalizado,
      tjam,
      sint,
      modifiedDataAdmissao,
      ultimaDesfilizacao,
    ]
  });

  const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([headers, ...dataArray]);

  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Relatorio');

  const excelContent: any[] = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

  const excelBytes: Uint8Array = new Uint8Array(excelContent);

  const blob: Blob = new Blob([excelBytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  const file: File = new File([blob], `relatorio_${new Date().toLocaleDateString()}.xlsx`, { type: blob.type });

  const url: string = URL.createObjectURL(file);

  const a: HTMLAnchorElement = document.createElement('a');
  a.href = url;
  a.download = file.name;
  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}