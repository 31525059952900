import Button from "../../components/Buttons";
import { Form } from "../../components/Form";

function Dependente(props: { index: number }) {
  return (
    <>
      <Form.Field>
        <Form.Label htmlFor={`Dependentes.${props.index}.Nome`}>Nome</Form.Label>
        <Form.Input id={`Dependentes.${props.index}.Nome`} name={`Dependentes.${props.index}.Nome`} type="text" />
        <Form.ErrorMessage field={`Dependentes.${props.index}.Nome`} />
      </Form.Field>
      <Form.Field>
        <Form.Label htmlFor={`Dependentes.${props.index}.Data_Nascimento`}>Data de Nascimento</Form.Label>
        <Form.Input id={`Dependentes.${props.index}.Data_Nascimento`} name={`Dependentes.${props.index}.Data_Nascimento`} type="date" />
      </Form.Field>
      <Form.Field>
        <Form.Label htmlFor={`Dependentes.${props.index}.CPF`}>CPF</Form.Label>
        <Form.Input id={`Dependentes.${props.index}.CPF`} name={`Dependentes.${props.index}.CPF`} type="text" />
        <Form.ErrorMessage field={`Dependentes.${props.index}.CPF`} />
      </Form.Field>
      <Form.Field>
          <Form.Label htmlFor={`Dependentes.${props.index}.Sexo`}>Sexo</Form.Label>
          <Form.Select id={`Dependentes.${props.index}.Sexo`} name={`Dependentes.${props.index}.Sexo`}>
            <Form.Option value="" label="Selecione uma opção" />
            <Form.Option value="1" label="Masculino" />
            <Form.Option value="2" label="Feminino" />
          </Form.Select>
        </Form.Field>
    </>
  )
}

export default function Dependentes({ fields, append, remove }: { fields: any, append: any, remove: any}) {
  return (
    <>
      <Button type="button" className="add-dependente mb-2" onClick={append}>Adicionar</Button>
      <div className="flex flex-col mt-2">
        {fields.map((field: any, index: number) => (
          <div key={index}>
            <Dependente index={index} />

            <button className="add-dependente mt-5" type="button" onClick={() => remove(index)}>
              Remover Dependente
            </button>
          </div>
          
        ))}
      </div>
    </>
  )
}