import React, { useState } from "react";
import { Home, Newspaper, User2Icon, HeartHandshake } from "lucide-react";
import CustomLink from "../CustomLink";

type IconName = "home" | "user" | "newspaper"|"handshake";

export default function Sidebar() {
  const [selectedIcon, setSelectedIcon] = useState<IconName | null>(null);

  const handleIconClick = (iconName: IconName) => {
    setSelectedIcon((prev) => (prev === iconName ? null : iconName));
  };

  const isIconSelected = (iconName: IconName) => iconName === selectedIcon;

  return (
    <div className=" bg-gray-200 top-0 h-screen scrollbar-hidden pb-4 flex flex-col ml-1 border-r-2 border-slate-300	">
      <CustomLink
        to="/home"
        className={`px-10 py-6 flex items-center relative ${
          isIconSelected("home") ? "bg-gradient-to-r from-red-100 via-red-200 to-transparenttext-red-500" : ""
        }`}
        onClick={() => handleIconClick("home")}
      >
        {isIconSelected("home") && (
          <div className="absolute left-0 top-1 h-16 w-1.5 rounded-r-md  bg-red-500"></div>
        )}
        <Home className={isIconSelected("home") ? "ml-2" : ""} />
      </CustomLink>

      <CustomLink
        to="/create"
        className={`px-10 py-6 flex items-center relative ${
          isIconSelected("user") ? "bg-gradient-to-r from-red-100 via-red-200 to-transparent" : ""
        }`}
        onClick={() => handleIconClick("user")}
      >
        {isIconSelected("user") && (
          <div className="absolute  top-1 left-0 h-16 w-1.5 rounded-r-md bg-red-500"></div>
        )}
        <User2Icon className={isIconSelected("user") ? "ml-2" : ""} />
      </CustomLink>

      <CustomLink
        to="/report"
        className={`px-10 py-6 flex items-center relative ${
          isIconSelected("newspaper") ? "bg-gradient-to-r from-red-100 via-red-200 to-transparent" : ""
        }`}
        onClick={() => handleIconClick("newspaper")}
      >
        {isIconSelected("newspaper") && (
          <div className="absolute top-1 left-0 h-16 w-1.5 rounded-r-md bg-red-500"></div>
        )}
        <Newspaper className={isIconSelected("newspaper") ? "ml-2" : ""} />
      </CustomLink>

      <CustomLink
        to="/pensionista"
        className={`px-10 py-6 flex items-center relative ${
          isIconSelected("handshake") ? "bg-gradient-to-r from-red-100 via-red-200 to-transparent" : ""
        }`}
        onClick={() => handleIconClick("handshake")}
      >
        {isIconSelected("handshake") && (
          <div className="absolute top-1 left-0 h-16 w-1.5 rounded-r-md bg-red-500"></div>
        )}
        <HeartHandshake className={isIconSelected("handshake") ? "ml-2" : ""} />
      </CustomLink>
    </div>
  );
}
