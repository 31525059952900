import { Form } from "../../components/Form";

export default function Pensionista() {
  return (
    <>
      <Form.Field>
        <Form.Label htmlFor="Pencionista.Nome">Nome</Form.Label>
        <Form.Input id="Pencionista.Nome" name="Pencionista.Nome" type="text" />
        <Form.ErrorMessage field="Pencionista.Nome" />
      </Form.Field>
      <Form.Field>
        <Form.Label htmlFor="Pencionista.Data_Nascimento">Data de Nascimento</Form.Label>
        <Form.Input id="Pencionista.Data_Nascimento" name="Data_Nascimento" type="date" />
        <Form.ErrorMessage field="Pencionista.Data_Nascimento" />
      </Form.Field>
      <Form.Field>
        <Form.Label htmlFor="Pencionista.CPF">CPF</Form.Label>
        <Form.Input id="Pencionista.CPF" name="Pencionista.CPF" type="text" />
        <Form.ErrorMessage field="Pencionista.CPF" />
      </Form.Field>
    </>
  )
}