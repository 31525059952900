import { Link, useResolvedPath, type LinkProps, useMatch } from "react-router-dom";

interface CustomLinkProps extends LinkProps {
  classNames?: string;
}

export default function CustomLink({ children, to, className, ...props }: CustomLinkProps) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });
  
  return (
    <div className={className}>
      <Link
        style={{ textDecoration: match ? "bg-red-500" : "none" }}
        to={to}
        {...props}
      >
        {children}
      </Link>
    </div>
  );
}