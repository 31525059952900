import { UserCheck2, UserRoundXIcon } from "lucide-react";

interface MembersCountProps {
  active: number;
  inactive: number;
}

export default function MembersCount({ data } : {data: MembersCountProps}) {
  return (
    <>
      <div className="container space-x-4 flex">
        <div className=" bg-red-600	 p-8 rounded-lg   ">
          <div className=" flex items-center space-x-4">
            <UserCheck2 className="text-white size-14" />
            <div className="grid justify-items-end">
              <h2 className="text-2xl font-bold mb-2 text-white flex items-center">
                Sindicalizados
              </h2>
              <div className="place-items-end">
                <p className="text-white mb-2 "> Ativos</p>
              </div>
            </div>
            <span className="text-white text-4xl	font-medium">{data?.active || 0}</span>
          </div>
        </div>
      </div>
      <div className=" bg-stone-700 p-8 rounded-lg   ">
        <div className=" flex items-center space-x-4">
          <UserRoundXIcon className="text-white size-14" />
          <div className="grid justify-items-end">
            <h2 className="text-2xl font-bold mb-2 text-white flex items-center">
              Sindicalizados
            </h2>
            <div className="place-items-end">
              <p className="text-white mb-2 "> Inativos</p>
            </div>
          </div>
          <span className="text-white text-4xl	font-medium">{data?.inactive || 0}</span>
        </div>
      </div>
    </>
  );
}
