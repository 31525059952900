export const filters = [
  {
    label: 'Status TJAM',
    type: 'select',
    value: 'Status',
  },
  {
    label: 'Status SINTJAM',
    type: 'select',
    value: 'Status_Sint',
  },
  {
    label: 'Categoria',
    type: 'select',
    value: 'Categoria',
  },
  {
    label: 'Classe',
    type: 'select',
    value: 'Classe_idClasse',
  },
  {
    label: 'Forma de Pagamento',
    type: 'select',
    value: 'Forma_Pagamento',
  },
  {
    label: 'Pensionista',
    type: 'select',
    value: 'Status_Sintjam',
  },
]