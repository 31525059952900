import { z } from 'zod'

const MAX_FILE_SIZE = 5000000;
const ACCEPTED_IMAGE_TYPES = ["image/jpeg", "image/jpg", "image/png", "image/webp", "application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];


export const updateDependenteSchema = z.object({
  Nome: z.string({}).optional(),
  Data_Nascimento: z.coerce.date().optional(),
  CPF: z.string().optional(),
  Sexo: z.union([z.string(), z.number()]).optional(),
});

export const updatePensionistaSchema = z.object({
  Nome: z.string({}).optional(),
  Data_Nascimento: z.coerce.date().optional(),
  CPF: z.string().optional(),
});

export const updateSindicalizadoSchema = z.object({
  CEP: z.string({
    required_error: 'CEP Precisa ser preenchido',
  }),
  CPF: z.string({
    required_error: 'CPF Precisa ser preenchido', 
  }),
  Categoria: z.string({
    required_error: 'Categoria Precisa ser preenchido',
  }),
  Celular: z.string({
    required_error: 'Celular Precisa ser preenchido',
  }),
  Cidade: z.string({
    required_error: 'Cidade Precisa ser preenchido',
  }),
  Classe_idClasse: z.union([z.string({
    required_error: 'Classe Precisa ser preenchido',
  }), z.number({
    required_error: 'Classe Precisa ser preenchido',
  })]),
  Data_Admissao: z.coerce.date({
    required_error: 'Data de Admissão Precisa ser preenchido',
  }).nullable(),
  Data_Cadastro: z.date().default(() => new Date()),
  Data_Expedicao: z.string({
  }).optional().nullable(),
  Data_Nascimento: z.coerce.date({
    required_error: 'Data de Nascimento Precisa ser preenchida',
  }),
  Email: z.string({
    required_error: 'Email Precisa ser preenchido',
  }),
  Estado: z.string({
    required_error: 'Estado Precisa ser preenchido',
  }),
  Forma_Pagamento: z.union([z.string({
    required_error: 'Forma de Pagamento Precisa ser preenchido',
  }), z.number({
    required_error: 'Forma de Pagamento Precisa ser preenchido',
  })]),
  Local: z.union([z.string({
    required_error: 'Local Precisa ser preenchido',
  }), z.number({
    required_error: 'Local Precisa ser preenchido',
  })]),
  Logradouro: z.string({
    required_error: 'Logradouro Precisa ser preenchido',
  }),
  Bairro: z.string({
    required_error: 'Bairro Precisa ser preenchido',
  }),
  Lotacao: z.string({
    required_error: 'Lotação Precisa ser preenchido',
  }),
  Mae: z.string({
    required_error: 'Mãe Precisa ser preenchido',
  }),
  Matricula: z.string({
    required_error: 'Matrícula Precisa ser preenchido',
  }),
  Matricula_Sint: z.string({
    required_error: 'Matrícula Sintjam Precisa ser preenchido',
  }),
  Nacionalidade: z.string().optional(),
  Naturalidade: z.string().optional(),
  Nome: z.string({
    required_error: 'Nome Precisa ser preenchido',
  }),
  Orgao: z.string().optional(),
  Pai: z.string().optional().nullable(),
  RG: z.string().optional(),
  Status: z.union([z.string({
  required_error: 'Status Precisa ser preenchido',
  }), z.number({
    required_error: 'Status Precisa ser preenchido',
  })]),
  Status_Sint: z.union([z.string({
    required_error: 'Status Sintjam Precisa ser preenchido',
  }), z.number({
    required_error: 'Status Sintjam Precisa ser preenchido',
  })]),
  Telefone: z.string().optional(),
  Dependentes: z.array(updateDependenteSchema).optional(),
  Pencionistas: updatePensionistaSchema,
  Documento: z.any()
  .refine(file => file.length === 1 ? ACCEPTED_IMAGE_TYPES.includes(file?.[0]?.type) ? true : false : true, 'Tipo de arquivo inválido. Escolha um arquivo do tipo PDF ou DOCX')
  .refine(file => file.length === 1 ? file[0]?.size <= MAX_FILE_SIZE ? true : false : true, 'Max file size allowed is 8MB.')
    .optional(),
  Foto: z.any()
  .refine(file => file.length === 1 ? ACCEPTED_IMAGE_TYPES.includes(file?.[0]?.type) ? true : false : true, 'Tipo de arquivo inválido. Escolha um arquivo do tipo JPG, JPEG, PNG ou WEBP')
  .refine(file => file.length === 1 ? file[0]?.size <= MAX_FILE_SIZE ? true : false : true, 'Max file size allowed is 8MB.')
    .optional()
});