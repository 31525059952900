import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, set, useForm } from 'react-hook-form';
import Button from '../../components/Buttons';
import { Form } from '../../components/Form';
import { createSignInSchema } from './schema';
import { useLocation } from 'react-router-dom';

import logo from "../../assets/logo.png"
import { Config } from '../../config';
import Modal from '../../components/Modal';
import { useAuth } from '../../hooks';

type CreateSignInData = z.infer<typeof createSignInSchema>;

export default function SignIn() {
  const location = useLocation();
  const state = location.state;
  const navigate = useNavigate();
  const { setUserName, setToken } = useAuth();
  const [modal, setModal] = useState({message: '', type: '', active: false});
  
  const createSignInForm = useForm<CreateSignInData>({
    resolver: zodResolver(createSignInSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = createSignInForm;

  useEffect(() => {
    if (modal.active) {
      setTimeout(() => {
        setModal({message: '', type: '', active: false});
      }, 3000);
    }
  }, [modal])

  useEffect(() => {
    if (state) {
      const { message, type, active } = state;
      setModal({message, type, active});
    }
  }, [state])

  

  const onSubmit = async (data: CreateSignInData) => {
    try {
      
      const response = await axios.post(Config.API.Login, data);

      if (response) {
        const {data} = response;
        setUserName(data.user.username);
        setToken(data.token);
        navigate('/home');
      } else {
        setModal({message: 'Usuário ou senha inválidos', type: 'error', active: true});
      }
    } catch (error) {
      console.log(error)
      setModal({message: 'Usuário ou senha inválidos', type: 'error', active: true});
    }
  };

  return (
    <>
    {
      modal.active && (
        <Modal message={modal.message} type={modal.type} />
      )
    }
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
    <div className='div bg-white p-6 rounded-md shadow-md backdrop-blur-md'>
      <div>
        <img src={logo} alt='logo' style={{ width: '300px'}} />
      </div>
      <FormProvider {...createSignInForm}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ marginBottom: '20px', width: '100%', textAlign: 'center' }}>
            <Form.Field>
              <Form.Label htmlFor="username" style={{ fontSize: '18px' }}>Usuario</Form.Label>
              <Form.Input
                id="username"
                name="username"
                type="text"
                style={{ borderBottom: '1px solid red', fontSize: '20px', textAlign: 'center' }}
              />
              <Form.ErrorMessage field="username" />
            </Form.Field>
          </div>
          <div style={{ marginBottom: '20px', width: '100%', textAlign: 'center' }}>
            <Form.Field>
              <Form.Label htmlFor="password" style={{ fontSize: '18px' }}>Senha</Form.Label>
              <Form.Input
                id="password"
                name="password"
                type="password"
                style={{ borderBottom: '1px solid red', fontSize: '20px', textAlign: 'center' }}
              />
              <Form.ErrorMessage field="password" />
            </Form.Field>
          </div>
          <Button type="submit" className="bg-red-500 text-white" style={{ width: '110px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '20px' }}>
            Entrar
          </Button>
        </form>
      </FormProvider>
    </div>
    </div>
    </>
  );
}
