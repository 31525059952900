// export const Config = {
//   API: {
//     SindicalizadosPensionistas:
//       "http://localhost:5000/sindicalizados_pensionista",
//     Sindicalizados: 'http://localhost:5000/sindicalizados',
//     Classe: 'http://localhost:5000/classes',
//     Dash: 'http://localhost:5000/sindicalizado/dash',
//     SindicalizadoUpload: 'http://localhost:5000/sindicalizados/upload',
//     Login: 'http://localhost:5000/auth/login',
//   }
// }

export const Config = {
  API: {
    Sindicalizados: 'https://api-siscad.sintjam.org.br/sindicalizados',
    SindicalizadosPensionistas: 'https://api-siscad.sintjam.org.br/sindicalizados_pensionista',
    Classe: 'https://api-siscad.sintjam.org.br/classes',
    Dash: 'https://api-siscad.sintjam.org.br/sindicalizado/dash',
    SindicalizadoUpload: 'https://api-siscad.sintjam.org.br/sindicalizados/upload',
    Login: 'https://api-siscad.sintjam.org.br/auth/login',
  }
}
