import { Form } from "../../components/Form";

interface DadosSindicaisProps {
  classes: any[];
}

export default function DadosSindicais({ classes }: DadosSindicaisProps) {
  return (
    <>
    <div className="grid grid-cols-3 gap-4">
       <Form.Field>
            <Form.Label htmlFor="Nome">Nome</Form.Label>
            <Form.Input id="Nome" name="Nome" type="text" />
            <Form.ErrorMessage field="Nome" />
          </Form.Field>
            <Form.Field>
              <Form.Label htmlFor="Matricula">Matricula</Form.Label>
              <Form.Input id="Matricula" name="Matricula" type="text" />
              <Form.ErrorMessage field="Matricula" />
            </Form.Field>
            <Form.Field >
              <Form.Label htmlFor="Matricula_Sint">
                Matricula SINTJAM
              </Form.Label>
              <Form.Input
                id="Matricula_Sint"
                name="Matricula_Sint"
                type="text"
                disabled
              />
              <Form.ErrorMessage field="Matricula_Sint" />
            </Form.Field>
              <Form.Field>
              <Form.Label htmlFor="Classe_idClasse">Classe</Form.Label>
              <Form.Select id="Classe_idClasse" name="Classe_idClasse">
                <Form.Option value="" label="Selecione uma opção" />
                {classes?.map((classe: any, index: number) => (
                  <Form.Option
                    key={classe.idClasse}
                    value={classe.idClasse}
                    label={classe.Descricao}
                  />
                ))}
              </Form.Select>
            </Form.Field>
            <Form.Field>
              <Form.Label htmlFor="Categoria">Categoria</Form.Label>
              <Form.Select id="Categoria" name="Categoria">
                <Form.Option value="" label="Selecione uma opção" />
                <Form.Option value="Servidor" label="Servidor" />
                <Form.Option value="Serventuário" label="Serventuário" />
                <Form.Option value="Magistrado" label="Magistrado" />
                <Form.Option value="Extra Judicial" label="Extra Judicial" />
              </Form.Select>
            </Form.Field>
            <Form.Field>
              <Form.Label htmlFor="Forma_Pagamento">
                Forma de Pagamento
              </Form.Label>
              <Form.Select id="Forma_Pagamento" name="Forma_Pagamento">
                <Form.Option value="" label="Selecione uma opção" />
                <Form.Option value="1" label="Desconto em Contracheque" />
                <Form.Option value="2" label="Em Espécie" />
              </Form.Select>
            </Form.Field>
            <Form.Field>
              <Form.Label htmlFor="Status">Situação TJAM</Form.Label>
              <Form.Select id="Status" name="Status">
                <Form.Option value="" label="Selecione uma opção" />
                <Form.Option value="1" label="Ativo" />
                <Form.Option value="2" label="Aposentado" />
                <Form.Option value="3" label="Pensionista" />
              </Form.Select>
            </Form.Field>
            <Form.Field>
              <Form.Label htmlFor="Status_Sint">Situação Sintjam</Form.Label>
              <Form.Select id="Status_Sint" name="Status_Sint">
                <Form.Option value="" label="Selecione uma opção" />
                <Form.Option value="1" label="Ativo" />
                <Form.Option value="2" label="Inativo" />
              </Form.Select>
            </Form.Field>
            <Form.Field >
              <Form.Label htmlFor="Data_Admissao">Data de Admissão</Form.Label>
              <Form.Input id="Data_Admissao" name="Data_Admissao" type="date" />
              <Form.ErrorMessage field="Data_Admissao" />
            </Form.Field>
            <Form.Field>
              <Form.Label htmlFor="Lotacao">Lotação</Form.Label>
              <Form.Input id="Lotacao" name="Lotacao" type="text" />
              <Form.ErrorMessage field="Lotacao" />
            </Form.Field>
            <Form.Field>
              <Form.Label htmlFor="Local">Localização</Form.Label>
              <Form.Select id="Local" name="Local">
                <Form.Option value="" label="Selecione uma opção" />
                <Form.Option value="1" label="Capital" />
                <Form.Option value="2" label="Interior" />
                <Form.Option value="3" label="Outros" />
              </Form.Select>
            </Form.Field>
    </div>
         
    </>
  );
}
