import { useState } from "react";
import ReactPaginate from 'react-paginate';

import SearchBar from "../Seach_bar";
import { Tabs } from "../Tabs";
import MemberItem from "./MemberItem";
import { Card } from "../Card";

export type MemberInfoProps = {
  idSindicalizado: number;
  CEP: string;
  CPF: string;
  Categoria: string;
  Celular: string;
  Cidade: string;
  Classe_idClasse: number;
  Data_Admissao: Date | string;
  Data_Nascimento: Date | string;
  Data_Cadastro: Date | string;
  Data_Expedicao: Date | string;
  Email: string;
  Estado: string;
  Forma_Pagamento: number;
  Local: number;
  Logradouro: string;
  Lotacao: string;
  Mae: string;
  Matricula: string;
  Matricula_Sint: string;
  Nacionalidade: string;
  Naturalidade: string;
  Nome: string;
  Nome_Classe: string;
  Orgao: string;
  Pai: string;
  RG: string;
  Sexo: string | null;
  Status: number;
  Status_Sint: number;
  Telefone: string;
  Ultima_Desfiliacao: Date | string | null;
  Dependentes?: {
    Nome?: string;
    Data_Nascimento?: Date | string;
    Sexo?: string | null;
    CPF?: string;
  }[]
  Pensionista?: {
    Nome?: string;
    CPF?: string;
    Data_Nascimento?: Date | string;
  }

  // PENSIONISTA DATA
  idSindicalizadoPensionista: number;
  Data_Falecimento_Sindicalizado: Date;
  Nome_Sindicalizado: string;
  CPF_Sindicalizado: string;
  Data_Sindicalizacao: Date;
  Status_Tjam: string | number;
  Status_Sintjam: string | number;
  Endereco: string;
  Matricula_Sintjam: string;
  Matricula_Tjam: string;
};

type ActionProps = {
  handlePageClick: React.Dispatch<React.SetStateAction<any>>
  currentPage: number;
  totalPages: number;
  totalItems?: number;
  selectedTab: string;
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>
  filterByName: (value: string) => void;
  handleEditAction: (value: number) => void;
  handleRegistrationAction: (value: number) => void;
  handleInactivateAction: (value: number) => void;
  handleActivateAction: (value: number) => void;
  handleGetDocumentAction: (value: number) => void;
}

export function ListMembers({ values, actions }: { values: MemberInfoProps[], actions: ActionProps}) {
  const tabs = [
    {id: 'sind_ativo', title: 'Ativos', content: <></>},
    {id: 'sind_inativo', title: 'Inativos', content: <></>},
    {id: 'pen_ativo', title: 'Pensionistas Ativos', content: <></>},
    {id: 'pen_inativo', title: 'Pensionnistas Inativos', content: <></>},
  ]

  return (
    <>
      <div className="container">
        <div className="flex justify-between">
          <Tabs.Tab>
            <Tabs.Titles items={tabs.map(({ id, title }) => ({ id, title }))} setSelectedTab={actions.setSelectedTab} />
              <Tabs.Contents
                items={tabs.map(({ id, content }) => ({
                  id,
                  content: <>{content}</>,
                }))}
              />
          </Tabs.Tab>
          <SearchBar className="members-search-bar" onChange={actions.filterByName} />
        </div>
        <Card.Body>
          <div>
            {
              values.map((value, key) => (
                <MemberItem
                  key={key}
                  member={value}
                  selectedTab={actions.selectedTab}
                  handleEditAction={actions.handleEditAction}
                  handleRegistrationAction={actions.handleRegistrationAction}
                  handleInactivateAction={actions.handleInactivateAction}
                  handleActivateAction={actions.handleActivateAction}
                  handleGetDocumentAction={actions.handleGetDocumentAction}
                />
              ))
            }
          </div>
        </Card.Body>
        <Card.Footer>
          <div className="flex">
            <ReactPaginate
              previousLabel={'Anterior'}
              nextLabel={'Próximo'}
              breakLabel={'...'}
              pageCount={actions.totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={actions.handlePageClick}
              containerClassName={'pagination'}
              activeClassName={'active'}
            />
          </div>
        </Card.Footer>
      </div>
    </>
  );
}
