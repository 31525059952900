import React, { ChangeEvent } from 'react';
import { useAuth, useAxios } from '../../hooks';
import { Config } from '../../config';

interface SelectFilterProps {
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
}


function TJAMFilter({ onChange }: SelectFilterProps) {
  return (
    <select className='appearance-none block bg-gray-200 text-gray-700 border border-gray-200 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500' onChange={onChange}>
      <option value="">Selecione uma opção</option>
      <option value={1}>Ativo</option>
      <option value={2}>Aposentado</option>
      <option value={3}>Pensionista</option>
    </select>
  );
}

function SINTJAMFilter({ onChange }: SelectFilterProps) {
  return (
    <select className='appearance-none block bg-gray-200 text-gray-700 border border-gray-200 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500' onChange={onChange}>
      <option value="">Selecione uma opção</option>
      <option value={1}>Ativo</option>
      <option value={2}>Inativo</option>
    </select>
  );
}

function CategoryFilter({ onChange }: SelectFilterProps) {
  return (
    <select className='appearance-none block bg-gray-200 text-gray-700 border border-gray-200 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500' onChange={onChange}>
      <option value="">Selecione uma opção</option>
      <option value="Servidor">Servidor</option>
      <option value="Serventuário">Serventuário</option>
      <option value="Magistrado">Magistrado</option>
      <option value="Extrajudicial">Extrajudicial</option>
    </select>
  );
}

function ClasseFilter({ onChange }: SelectFilterProps) {
  const { token } = useAuth();
  const { response: getDataResponse, loading: getDataLoading, error: getDataError } = useAxios({
    url: `${Config.API.Classe}`,
    method: 'GET',
    token,
  });
  return (
    <select className='appearance-none block bg-gray-200 text-gray-700 border border-gray-200 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500' onChange={onChange}>
      <option value="">Selecione uma opção</option>
      {
        getDataResponse?.data?.map((classe: any, index: number) => (
          <option key={classe.idClasse} value={classe.idClasse}>{classe.Descricao}</option>
        ))
      }
    </select>
  )
}

function PaymentTypeFilter({ onChange }: SelectFilterProps) {
  return (
    <select className='appearance-none block bg-gray-200 text-gray-700 border border-gray-200 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500' onChange={onChange}>
      <option value="">Selecione uma opção</option>
      <option value={1}>Desconto no Contracheque</option>
      <option value={2}>Em espécie</option>
    </select>
  );
}

function PensionistaFilter({ onChange }: SelectFilterProps) {
  return (
    <select className='appearance-none block bg-gray-200 text-gray-700 border border-gray-200 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500' onChange={onChange}>
      <option value="">Selecione uma opção</option>
      <option value={1}>Ativo</option>
      <option value={2}>Inativo</option>
    </select>
  );
}

export {
  TJAMFilter,
  SINTJAMFilter,
  CategoryFilter,
  ClasseFilter,
  PaymentTypeFilter,
  PensionistaFilter,
}