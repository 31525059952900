import 'jspdf-autotable';
import { jsPDF } from 'jspdf';

type MemberInfoProps = {
  CEP: string;
  CPF: string;
  Categoria: string;
  Celular: string;
  Cidade: string;
  Classe_idClasse: number;
  Data_Admissao: Date | string;
  Email: string;
  Estado: string;
  Forma_Pagamento: number;
  Local: number;
  Logradouro: string;
  Lotacao: string;
  Mae: string;
  Matricula: string;
  Matricula_Sint: string;
  Nacionalidade: string;
  Naturalidade: string;
  Nome: string;
  Nome_Classe: string;
  Orgao: string;
  Pai: string;
  RG: string;
  Sexo: string | null;
  Status: number;
  Status_Sint: number;
  Telefone: string;
  UltimaDesfiliacao: Date | string | null;
};

export function generatePDF(values: MemberInfoProps[]) {
  const pdf = new jsPDF({
    orientation: 'landscape',
    unit: 'mm',
    format: 'a4',
  });

  const headers = ['Matricula', 'Nome', 'Email', 'Celular'];

  // Definir os dados da tabela
  const data = values.map((member) => [
    member.Matricula,
    member.Nome,
    member.Email,
    member.Celular,
  ]);

  // Adicionar a tabela ao documento PDF
  (pdf as any).autoTable({
    head: [headers],
    body: data,
    startY: 10, // Posição inicial y da tabela
    styles: { cellPadding: 1, fontSize: 10 },
  });

  pdf.save('relatorio.pdf');
}
