import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Form } from "../../components/Form";
import axios from "axios";
import { useAuth, useAxios } from "../../hooks";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { RegisterPensionistaSchema } from "./schema_Pensionista";
import { Config } from "../../config";
import { generateMatricula } from "../../helpers/Matricula";
import { Card } from "../../components/Card";
import Modal from "../../components/Modal";

type RegisterPensionistaData = z.infer<typeof RegisterPensionistaSchema>;

function CreatePensionista() {
  const { token } = useAuth();
  const navigate = useNavigate();
  const [modal, setModal] = useState({message: '', type: '', active: false});
  const createUserForm = useForm<RegisterPensionistaData>({
    resolver: zodResolver(RegisterPensionistaSchema),
  });
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    watch,
    setValue,
  } = createUserForm;

  const createPensionista = async (data: any) => {
    try {
      const response = await axios.post(Config.API.SindicalizadosPensionistas,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
          setModal({message: 'Successo ao criar', type: 'success', active: true});
          setTimeout(() => {
            navigate(`/home`);
          }, 3000);
      } else {
        setModal({message: 'Error ao criar pensionista', type: 'error', active: true});
      }
    } catch (error) {
      setModal({message: 'Error ao criar pensionista', type: 'error', active: true});
    }
  };

  useEffect(() => {
    setValue('Matricula_Sintjam', generateMatricula());
  }, [setValue]);

  return (
    <div>
      {
        modal.active && <Modal message={modal.message} type={modal.type} />
      }
      <Card.CardContent>
        <Card.Header>
          <h1>Cadastro de Pensionista</h1>
        </Card.Header>
        <FormProvider {...createUserForm}>
          <form onSubmit={handleSubmit(createPensionista)}>
            <div className="grid grid-cols-3 gap-4">
              <Form.Field>
                <Form.Label htmlFor="Nome">Nome</Form.Label>
                <Form.Input id="Nome" name="Nome" type="text" />
                <Form.ErrorMessage field="Nome" />
              </Form.Field>

              <Form.Field>
                <Form.Label htmlFor="Data_Nascimento">Data de Nascimento</Form.Label>
                <Form.Input id="Data_Nascimento" name="Data_Nascimento" type="date" />
                <Form.ErrorMessage field="Data_Nascimento" />
              </Form.Field>

              <Form.Field>
                <Form.Label htmlFor="CPF">CPF</Form.Label>
                <Form.Input id="CPF" name="CPF" type="text" />
                <Form.ErrorMessage field="CPF" />
              </Form.Field>

              <Form.Field>
                <Form.Label htmlFor="Celular">Celular</Form.Label>
                <Form.Input id="Celular" name="Celular" type="text" />
                <Form.ErrorMessage field="Celular" />
              </Form.Field>

              <Form.Field>
                <Form.Label htmlFor="Data_Falecimento_Sindicalizado">Data de Falecimento do Sindicalizado</Form.Label>
                <Form.Input id="Data_Falecimento_Sindicalizado" name="Data_Falecimento_Sindicalizado" type="date" />
                <Form.ErrorMessage field="Data_Falecimento_Sindicalizado" />
              </Form.Field>

              <Form.Field>
                <Form.Label htmlFor="Nome_Sindicalizado">Nome do Sindicalizado</Form.Label>
                <Form.Input id="Nome_Sindicalizado" name="Nome_Sindicalizado" type="text" />
                <Form.ErrorMessage field="Nome_Sindicalizado" />
              </Form.Field>

              <Form.Field>
                <Form.Label htmlFor="CPF_Sindicalizado">CPF do Sindicalizado</Form.Label>
                <Form.Input id="CPF_Sindicalizado" name="CPF_Sindicalizado" type="text" />
                <Form.ErrorMessage field="CPF_Sindicalizado" />
              </Form.Field>

              <Form.Field>
                <Form.Label htmlFor="Data_Sindicalizacao">Data de Sindicalização</Form.Label>
                <Form.Input id="Data_Sindicalizacao" name="Data_Sindicalizacao" type="date" />
                <Form.ErrorMessage field="Data_Sindicalizacao" />
              </Form.Field>

              <Form.Field>
                <Form.Label htmlFor="Forma_Pagamento">
                  Forma de Pagamento
                </Form.Label>
                <Form.Select id="Forma_Pagamento" name="Forma_Pagamento">
                  <Form.Option value="" label="Selecione uma opção" />
                  <Form.Option value="1" label="Desconto em Contracheque" />
                  <Form.Option value="2" label="Em Espécie" />
                </Form.Select>
              </Form.Field>

              <Form.Field>
                <Form.Label htmlFor="Status_Tjam">Situação TJAM</Form.Label>
                <Form.Select id="Status_Tjam" name="Status_Tjam">
                  <Form.Option value="" label="Selecione uma opção" />
                  <Form.Option value="1" label="Ativo" />
                  <Form.Option value="2" label="Inativo" />
                </Form.Select>
              </Form.Field>

              <Form.Field>
                <Form.Label htmlFor="Status_Sintjam">Situação Sintjam</Form.Label>
                <Form.Select id="Status_Sintjam" name="Status_Sintjam">
                  <Form.Option value="" label="Selecione uma opção" />
                  <Form.Option value="1" label="Ativo" />
                  <Form.Option value="2" label="Inativo" />
                </Form.Select>
              </Form.Field>

              <Form.Field>
                <Form.Label htmlFor="Endereco">Endereço</Form.Label>
                <Form.Input id="Endereco" name="Endereco" type="text" />
                <Form.ErrorMessage field="Endereco" />
              </Form.Field>

              <Form.Field >
                <Form.Label htmlFor="Matricula_Sintjam">
                  Matricula SINTJAM
                </Form.Label>
                <Form.Input
                  id="Matricula_Sintjam"
                  name="Matricula_Sintjam"
                  type="text"
                  disabled
                />
                <Form.ErrorMessage field="Matricula_Sintjam" />
              </Form.Field>

              <Form.Field>
                <Form.Label htmlFor="Matricula_Tjam">Matrícula TJAM</Form.Label>
                <Form.Input id="Matricula_Tjam" name="Matricula_Tjam" type="text" />
                <Form.ErrorMessage field="Matricula_Tjam" />
              </Form.Field>
            </div>
            <button type="submit"  className=" p-4 bg-red-500 text-white mt-4 rounded">Cadastrar</button>
          </form>
        </FormProvider>
      </Card.CardContent>
    </div>
)}

export default CreatePensionista;


