import * as XLSX from 'xlsx';

type MemberInfoProps = {
  Matricula: string;
  Matricula_Sint: string;
  Nome: string;
  CPF: string;
  CEP: string;
  Categoria: string;
  Nome_Classe: string;
  Data_Nascimento: Date | string;
  Celular: string;
  Email: string;
  Estado: string;
  Cidade: string;
  Local: number;
  Logradouro: string;
  Forma_Pagamento: number;
  Data_Admissao: Date | string;
  Lotacao: string;
  Mae: string;
  Nacionalidade: string;
  Naturalidade: string;
  Orgao: string;
  Pai: string;
  RG: string;
  Status: number;
  Status_Sint: number;
  Telefone: string;
  Ultima_Desfiliacao: Date | string;
};

export function generateExcel (data: MemberInfoProps[]) {
  if (data.length === 0) {
    return;
  }

  const headers = [
    'Matricula',
    'Matricula Sintjam',
    'Nome',
    'CPF',
    'Data de Nascimento',
    'RG',
    'Orgao',
    'Categoria',
    'Classe',
    'Celular',
    'Telefone',
    'Email',
    'Estado',
    'Cidade',
    'CEP',
    'Local',
    'Logradouro',
    'Forma de Pagamento',
    'Lotacao',
    'Mae',
    'Pai',
    'Nacionalidade',
    'Naturalidade',
    'Status TJAM',
    'Status Sintjam',
    'Data de Admissão',
    'Ultima Desfiliação',
  ]

  const dataArray = data.map((member) => {
    console.log(member.Ultima_Desfiliacao)
    const modifiedDataAdmissao = new Date(member.Data_Admissao).toLocaleDateString('pt-BR');
    const dataNascimento = new Date(member.Data_Nascimento).toLocaleDateString('pt-BR');
    let ultimaDesfilizacao = member.Ultima_Desfiliacao ? new Date(member.Ultima_Desfiliacao).toLocaleDateString('pt-BR') : '';
    let tjam = '';
    let sint = '';
    let forma = '';
    let local = '';

    if (member.Status === 1) {
      tjam = 'Ativo';
    } else if(member.Status === 2) {
        tjam = 'Aponsentado';
    } else {
        tjam = 'Pensionista';
    }

    if (member.Status_Sint === 1) {
        sint = 'Ativo';
    } else if (member.Status_Sint === 2) {
        sint = 'Inativo';
    }

    if (member.Forma_Pagamento === 1) {
        forma = 'Desconto no Contracheque';
    }
    else {
        forma = 'Em Espécie';
    }

    if (member.Local === 1) {
      local = 'Capital';
    } else if (member.Local === 2) {
      local = 'Interior';
    } else {
      local = 'Outros';
    }

  
    return [
      member.Matricula,
      member.Matricula_Sint,
      member.Nome,
      member.CPF,
      dataNascimento,
      member.RG,
      member.Orgao,
      member.Categoria,
      member.Nome_Classe,
      member.Celular,
      member.Telefone,
      member.Email,
      member.Estado,
      member.Cidade,
      member.CEP,
      local,
      member.Logradouro,
      forma,
      member.Lotacao,
      member.Mae,
      member.Pai,
      member.Nacionalidade,
      member.Naturalidade,
      tjam,
      sint,
      modifiedDataAdmissao,
      ultimaDesfilizacao,
    ]
  });

  const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([headers, ...dataArray]);

  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Relatorio');

  const excelContent: any[] = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

  const excelBytes: Uint8Array = new Uint8Array(excelContent);

  const blob: Blob = new Blob([excelBytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  const file: File = new File([blob], `relatorio_${new Date().toLocaleDateString()}.xlsx`, { type: blob.type });

  const url: string = URL.createObjectURL(file);

  const a: HTMLAnchorElement = document.createElement('a');
  a.href = url;
  a.download = file.name;
  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}