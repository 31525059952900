import 'jspdf-autotable';
import { jsPDF } from 'jspdf';

type MemberInfoProps = {
  CPF: string;
  Data_Nascimento: Date | string;
  Nome: string;
  idSindicalizadoPensionista: number;
  Data_Falecimento_Sindicalizado: Date;
  Nome_Sindicalizado: string;
  CPF_Sindicalizado: string;
  Data_Sindicalizacao: Date;
  Status_Tjam: string | number;
  Status_Sintjam: string | number;
  Endereco: string;
  Matricula_Sintjam: string;
  Matricula_Tjam: string;
  Ultima_Desfiliacao: Date | string | null;
  Forma_Pagamento: number;
  Celular: string;
};

export function generatePDFPensionista(values: MemberInfoProps[]) {
  const pdf = new jsPDF({
    orientation: 'landscape',
    unit: 'mm',
    format: 'a4',
  });

  const headers = ['Matricula TJAM', 'Nome Pensionista', 'Nome Sindicalizado', 'Celular'];

  // Definir os dados da tabela
  const data = values.map((member) => [
    member.Matricula_Tjam,
    member.Nome,
    member.Nome_Sindicalizado,
    member.Celular,
  ]);

  // Adicionar a tabela ao documento PDF
  (pdf as any).autoTable({
    head: [headers],
    body: data,
    startY: 10, // Posição inicial y da tabela
    styles: { cellPadding: 1, fontSize: 10 },
  });

  pdf.save('relatorio.pdf');
}
