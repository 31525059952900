import { useEffect, useState } from "react";
import { CheckCircle, XCircle } from "lucide-react";
export default function Modal({ message, type }: { message: string, type: string}) {
  const [modalActive, setModalActive] = useState<boolean>(true);
  
  useEffect(() => {
    setTimeout(() => {
      setModalActive(false);
    }, 3000);
  }, []);

  if (!modalActive) {
    return null;
  }

  return (
    <>
    <div className="modal-overlay w-full h-full flex justify-center align-middle">
      <div className="modal">
        <div className="modal-content flex flex-col justify-center align-middle">
          {
            type === "success" ? <CheckCircle className="icon-success" /> : <XCircle className="icon-error" />
          }
          <p>{message}</p>
        </div>
      </div>
    </div>
    </>
  );
}