import { useEffect, useState } from 'react';
import axios, { AxiosResponse, AxiosError, Method, AxiosRequestConfig } from 'axios';

interface RequestOptions {
  url: string;
  method: Method;
  data?: any;
  token?: string | null;
}

interface UseAxiosProps extends RequestOptions {
  onSuccess?: (data: any) => void;
  onError?: (error: AxiosError) => void;
}

const useAxios = ({ url, method, data, token, onSuccess, onError }: UseAxiosProps) => {
  const [response, setResponse] = useState<AxiosResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<AxiosError | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config: AxiosRequestConfig = { method, url, data, headers: { 
          'Authorization': `Bearer ${token}`
        } };
        const axiosResponse: AxiosResponse<any> = await axios(config);
        setResponse(axiosResponse);

        if (onSuccess) {
          onSuccess(axiosResponse.data);
        }
      } catch (axiosError) {
        setError(axiosError as AxiosError<any>);

        if (onError) {
          onError(axiosError as AxiosError<any>);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url, method, data, onSuccess, onError, token]);

  return { response, loading, error };
};

export default useAxios;
