import { useState } from 'react';
import { filters } from './filter';
import { 
  TJAMFilter,
  SINTJAMFilter,
  CategoryFilter,
  ClasseFilter,
  PaymentTypeFilter,
  PensionistaFilter,
} from './Filters';
import { generatePDF } from '../../helpers/ReportPDF';
import { generatePDFPensionista } from '../../helpers/ReportPDF_Pensionista';
import { generateExcelPensionista } from '../../helpers/ReportExcel_Pensionista';
import { generateExcel } from '../../helpers/ReportExcel';
import { Card } from '../../components/Card';
import axios from 'axios';
import { useAuth } from '../../hooks';
import { Config } from '../../config';

interface FilterValues {
  name: string;
  value: string | number;
}

interface IFilterValues {
  setFilterValues: React.Dispatch<React.SetStateAction<FilterValues[]>>
}

function Filter({ setFilterValues }: IFilterValues) {
  const [selectedFilter, setSelectedFilter] = useState<string>('');

  const handleSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedFilter(e.target.value);
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const valueChange = e.target.value;
    let value: string | number = valueChange;
    if (!isNaN(parseFloat(valueChange)) && Number.isInteger(parseFloat(valueChange))) {
      const numeroInteiro = parseInt(valueChange, 10);
      value = numeroInteiro;
    }

    const obj = {
      name: selectedFilter,
      value,
    }
    setFilterValues((prev) => [...prev, obj]);
  };

  const filterComponent = {
    'Status': <TJAMFilter onChange={handleChange} />,
    'Status_Sint': <SINTJAMFilter onChange={handleChange} />,
    'Categoria': <CategoryFilter onChange={handleChange} />,
    'Classe_idClasse': <ClasseFilter onChange={handleChange} />,
    'Forma_Pagamento': <PaymentTypeFilter onChange={handleChange} />,
    // Pensionista
    'Status_Sintjam': <PensionistaFilter onChange={handleChange} />,
    '': <></>,
  }[selectedFilter || ''];


  return (
    <div className='flex mt-2'>
      <select className='appearance-none block bg-gray-400 text-gray-700 border border-gray-200 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mr-5' onChange={handleSelectFilter}>
        <option value="">Selecione uma opção</option>
        {filters.map((filter, index) => (
          <option key={index} value={filter.value}>
            {filter.label}
          </option>
        ))}
      </select>
      {filterComponent}
    </div>
  );
}
 
export default function Report() {
  const { token } = useAuth();
  const [filterList, setFilterList] = useState<React.ReactNode[]>([]);
  const [filterValues, setFilterValues] = useState<FilterValues[]>([]);
  const [reportType, setReportType] = useState<string>('pdf');

  const handleAddFilter = () => {
    setFilterList([...filterList, <Filter key={filterList.length} setFilterValues={setFilterValues} />]);
  };

  const handleSelectReportType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setReportType(e.target.value);
  }

  const buildFilters = () => {
    const filters = filterValues.map((filter) => ({
      key: filter.name,
      value: filter.value,
    }));
    return filters;
  }

  function handleGenerateReportSindicalizados(filters: any) {
    if (reportType === 'pdf') {
      axios.post(`${Config.API.Sindicalizados}/report`, {
        filters
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then((response: any) => {
        const { sindicalizados } = response.data;
        generatePDF(sindicalizados);
      });
    }else {
      axios.post(`${Config.API.Sindicalizados}/report`, {
        filters
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then((response: any) => {
        const { sindicalizados } = response.data;
        generateExcel(sindicalizados);
      });
    }
  }

  function handleGenerateReportPensionista(filters: any) {
    if (reportType === 'pdf') {
      axios.post(`${Config.API.SindicalizadosPensionistas}/report`, {
        filters
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then((response: any) => {
        const { sindicalizados } = response.data;
        generatePDFPensionista(sindicalizados);
      });
    }else {
      axios.post(`${Config.API.SindicalizadosPensionistas}/report`, {
        filters
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then((response: any) => {
        const { sindicalizados } = response.data;
        generateExcelPensionista(sindicalizados);
      });
    }
  }

  const handleGenerateReport = () => {
    const filters = buildFilters()
      .filter(filter => filter.key !== "Pensionista")
      .map(filter => ({
        key: filter.key,
        value: filter.value,
      }));
    
    const filterPensionista = buildFilters()
      .filter(filter => filter.key === "Status_Sintjam")
      .map(filter => ({
        key: filter.key,
        value: filter.value,
      }));

    if (filterPensionista.length > 0) {
      handleGenerateReportPensionista(filterPensionista);
    } else {
      handleGenerateReportSindicalizados(filters);
    }
  }

  return (
    <div>
      <Card.CardContent>
        <Card.Header>
          <h1>Relatório</h1>
        </Card.Header>
        <Card.Body>
          <button className='btn-add-filter' onClick={handleAddFilter}>Adicionar Filtro</button>
          <div className='flex flex-col'>
            {filterList}
          </div>
        </Card.Body>
        <Card.Footer>
          <div className='flex'>
            <button className='btn-report' onClick={handleGenerateReport} disabled={filterValues.length <= 0 ? true: false}>Gerar Relatório</button>
            <select onChange={handleSelectReportType} className='appearance-none block bg-gray-400 text-gray-700 border border-gray-200 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500'>
              <option value="pdf">PDF</option>
              <option value="excel">Excel</option>
            </select>
          </div>
        </Card.Footer>
      </Card.CardContent>
    </div>
  )
}