import React, { createContext, useContext, ReactNode, useState, useEffect } from 'react';
import { useNavigate } from 'react-router';


type AuthContextType = {
  username: string | null;
  token: string | null;
  setUserName: (name: string) => void;
  setToken: (token: string) => void;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

type AuthProviderProps = {
  children: ReactNode;
};

const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState<string | null>(() => {
    return localStorage.getItem('username') || null;
  });

  const [token, setToken] = useState<string | null>(() => {
    return localStorage.getItem('token') || null;
  });

  const setUserName = (name: string) => {
    setUsername(name);
  };

  const setAuthToken = (newToken: string) => {
    setToken(newToken);
  };

  useEffect(() => {
    const savedToken = localStorage.getItem('token');
    if (savedToken) {
      const parsedToken = parseJwt(savedToken);
      const expirationTime = parsedToken?.exp * 1000 || 0;

      
      if (expirationTime < Date.now() || Date.now() - expirationTime > 8 * 60 * 60 * 1000) {
        localStorage.clear();
        navigate('/', {
          state: {
            message: 'Sua sessão expirou',
            type: 'error',
            active: true,
          }
        });
        return;
      }else {
        setToken(savedToken);
      }
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('username', username || '');
  }, [username]);

  useEffect(() => {
    localStorage.setItem('token', token || '');
  }, [token]);

  return (
    <AuthContext.Provider value={{ username, token, setUserName, setToken: setAuthToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
