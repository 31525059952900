import { z } from "zod";
import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useFieldArray, useForm } from "react-hook-form";

import { createSindicalizadoSchema  } from './form_schema';
import { useEffect, useState } from "react";
import { Tabs } from "../../components/Tabs";
import DadosSindicais from "./DadosSindicais";
import DadosPessoais from "./DadosPessoais";
import Localizacao from "./Localização";
import Dependentes from "./Dependentes";
import Pensionista from "./Pensionista";
import Anexos from "./Anexos";
import { Card } from "../../components/Card";
import { useAuth, useAxios } from "../../hooks";
import axios from "axios";
import Modal from "../../components/Modal";
import { useNavigate } from "react-router-dom";
import { Config } from "../../config";
import { generateMatricula } from "../../helpers/Matricula";


type CreateSindicalizadoData = z.infer<typeof createSindicalizadoSchema>;

export default function Create() {
  const { token } = useAuth();
  const [modal, setModal] = useState({message: '', type: '', active: false});
  const navigate = useNavigate();
  const {
    response: getDataResponse,
    loading: getDataLoading,
    error: getDataError,
  } = useAxios({
    url: `${Config.API.Classe}`,
    method: "GET",
    token,
  });
  const createUserForm = useForm<CreateSindicalizadoData>({
    resolver: zodResolver(createSindicalizadoSchema),
  })

  const { 
    handleSubmit, 
    formState: { isSubmitting, errors }, 
    watch,
    control,
    setValue,
  } = createUserForm;

  useEffect(() => {
    if (modal.active) {
      setTimeout(() => {
        setModal({message: '', type: '', active: false});
      }, 3000);
    }
  }, [modal])

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'Dependentes',
  });

  const matriculaValue = watch('Matricula');

  useEffect(() => {
    setValue('Matricula_Sint', generateMatricula());
  }, [matriculaValue, setValue]);

  async function createUser(data: CreateSindicalizadoData) {
    try {
      const { data: resp, status } = await axios.post(Config.API.Sindicalizados, data, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })

      if(resp?.id > 0) {
        if (data.Documento[0]) {
          const formData = new FormData();
          formData.append('file', data.Documento[0]);
          formData.append('idSindicalizado', resp?.id);

          await axios.post(Config.API.SindicalizadoUpload, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${token}`
            },
          }).then((response) => { console.log(response)}).catch(errors => console.log(errors));
        }else if (data.Foto[0]) {
          const formData = new FormData();
          formData.append('file', data.Foto[0]);
          formData.append('idSindicalizado', resp?.id);

          await axios.post(Config.API.SindicalizadoUpload, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${token}`
            },
          }).then((response) => { console.log(response)}).catch(errors => console.log(errors));
        }
        
      }

      if (status < 300) {
        setModal({message: 'Successo ao criar', type: 'success', active: true});
        setTimeout(() => {
          navigate(`/home`);
        }, 3000);
      }

    } catch (error) {
      console.log(error)
    }
    
  }
  

  const tabs = [
    { id: 'tab1_id', title: 'Dados Sindicais', content: <DadosSindicais classes={getDataResponse?.data} /> },
    { id: 'tab2_id', title: 'Dados Pessoais', content: <DadosPessoais /> },
    { id: 'tab3_id', title: 'Localização', content: <Localizacao /> },
    { id: 'tab4_id', title: 'Dependentes', content: <Dependentes fields={fields} append={append} remove={remove} /> },
    { id: 'tab5_id', title: 'Pensionistas', content: <Pensionista /> },
    { id: 'tab6_id', title: 'Documentos / Anexos', content: <Anexos /> },
  ]; 


  return (
    <>
      {
        modal.active && <Modal message={modal.message} type={modal.type} />
      }
      <Card.CardContent>
        <Card.Header>
          <h1>Cadastro de Sindicalizado</h1>
        </Card.Header>
          <FormProvider {...createUserForm}>
          
            <form 
              onSubmit={handleSubmit(createUser)}
            >
              <Card.Body>  
                <Tabs.Tab>
                  <Tabs.Titles items={tabs.map(({ id, title }) => ({ id, title }))} />
                  <Tabs.Contents
                    items={tabs.map(({ id, content }) => ({
                      id,
                      content: <>{content}</>,
                    }))}
                  />
                </Tabs.Tab>
              </Card.Body>
              <Card.Footer>
                <button type="submit" className="submit-input">Cadastrar</button>
              </Card.Footer>
            </form>
        </FormProvider>

      </Card.CardContent>
    </>
  )
}