import { OptionHTMLAttributes } from 'react';

interface OptionProps extends OptionHTMLAttributes<HTMLOptionElement> {
  value: string;
  label: string;
}

export function Option({ value, label, ...rest }: OptionProps) {

  return (
    <option value={value} {...rest}>
      {label}
    </option>
  );
}