import { z } from "zod";

export const RegisterPensionistaSchema = z.object({
  Nome: z.string({
    required_error: "Precisa ser preenchido corretamente"
  }),
  Data_Nascimento: z.coerce.date(),
  CPF: z.string({
    required_error: "CPF Precisa ser preenchido",
  }),
  Celular: z.string({
    required_error: "Celular Precisa ser preenchido",
  }),
  Data_Falecimento_Sindicalizado: z.coerce.date(),
  Nome_Sindicalizado: z.string({
    required_error: "Precisa ser preenchido corretamente"
  }),
  CPF_Sindicalizado: z.string({
    required_error: " CPF Precisa ser preenchido",
  }),
  Data_Sindicalizacao: z.coerce.date(),
  Status_Tjam: z.union([z.string({
    required_error: 'Precisa ser preenchido',
  }), z.number({
    required_error: 'Precisa ser preenchido',
  })]),
  Status_Sintjam: z.union([z.string({
    required_error: 'Precisa ser preenchido',
  }), z.number({
    required_error: 'Precisa ser preenchido',
  })]),
  Ultima_Desfiliacao: z.coerce.date().optional(),
  Forma_Pagamento: z.union([z.string({
    required_error: 'Forma de Pagamento Precisa ser preenchido',
  }), z.number({
    required_error: 'Forma de Pagamento Precisa ser preenchido',
  })]),
  Endereco: z.string({
    required_error: "Endereço Precisa ser preenchido",
  }),
  Matricula_Sintjam: z.string({
    required_error: "Precisa ser preenchido corretamente"
  }),
  Matricula_Tjam: z.string({
    required_error: "Precisa ser preenchido corretamente"
  }),
});
