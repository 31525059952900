import { Bell, UserCircle2 } from "lucide-react";
import logo from "../../assets/logo.png";
import Button from "../Buttons";
import { useAuth } from "../../hooks";

export default function Navbar() {
  const { username } = useAuth();
  return (
    <>
      <div className=" bg-gray-200 flex gap-10 lg:gap-20 justify-between pt-2 ">
        <div className="flex gap-4 items-center flex-shrink-0">
          <div className="image-logo">
            <img src={logo} alt="logo" />
          </div>
        </div>
        <div className="flex gap-4 items-center">
          <div className="bell">
            <Button variant="ghost" size="default">
              <Bell />
            </Button>
          </div>
          <div className="user ">
            <Button variant="ghost" size="default" className="flex flex-column  items-center">
              <div className="mr-2">
                <p className="Name">
                  {username}
                </p>
                <p className="admin">
                </p>
              </div>
              <UserCircle2 />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
