import { VariantProps, cva } from "class-variance-authority";
import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

const buttonStyles = cva(["transiton-colors"],{
  variants:{
    variant:{
      dafault:["bg-secondary-color","hover: bg-secondary-hover"],
      ghost:["hover:bg-gray-300"]
    },

    size: {
      default: ["rounded","px-2","py-2"],
      icon: ["rounded-full",
      "w-20px",
      "h-20px",
      "flex",
      "items-center",
      "justify-center",
      "p-2.5",]
    },
  },
  defaultVariants: {
    variant: "dafault",
    size: "default",
  }
})

type ButtonProps = VariantProps<typeof buttonStyles> & ComponentProps<"button">

export default function Button({variant, size,className,...props}: ButtonProps){
  return (<button {...props} className={twMerge (buttonStyles({ variant, size }),className)}
  />
);
    
}