import { useTabsContext } from "./TabsContext"

type TabContentProps = {
  items: {
    id: string
    content: React.ReactNode
  }[]
}

export default function Contents ({ items }: TabContentProps) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { currentIndex } = useTabsContext()
  const { id, content } = items[currentIndex]
  return (
    <div
      key={id}
      id={`tab-content-${id}`}
      role="tabpanel"
      aria-labelledby={`tab-control-${id}`}
      className="tab-content"
    >
      {content}
    </div>
  )
}