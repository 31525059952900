import { Form } from "../../components/Form";

export default function Localizacao() {
  return (
    <>
      <Form.Field>
        <Form.Label htmlFor="CEP">CEP</Form.Label>
        <Form.Input id="CEP" name="CEP" type="text" />
        <Form.ErrorMessage field="CEP" />
      </Form.Field>
      <Form.Field>
        <Form.Label htmlFor="Estado">Estado</Form.Label>
        <Form.Input id="Estado" name="Estado" type="text" />
        <Form.ErrorMessage field="Estado" />
      </Form.Field>
      <Form.Field>
        <Form.Label htmlFor="Cidade">Cidade</Form.Label>
        <Form.Input id="Cidade" name="Cidade" type="text" />
        <Form.ErrorMessage field="Cidade" />
      </Form.Field>
      <Form.Field>
        <Form.Label htmlFor="Logradouro">Logradouro</Form.Label>
        <Form.Input id="Logradouro" name="Logradouro" type="text" />
        <Form.ErrorMessage field="Logradouro" />
      </Form.Field>
      <Form.Field>
        <Form.Label htmlFor="Bairro">Bairro</Form.Label>
        <Form.Input id="Bairro" name="Bairro" type="text" />
        <Form.ErrorMessage field="Bairro" />
      </Form.Field>
    </>
  );
}