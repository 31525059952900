import React, { useEffect, useMemo, useState } from "react";
import { ListMembers } from "../../components/List_members";
import MembersCount from "../../components/Members_count";
import { members } from "../../data/home";
import { Card } from "../../components/Card";
import { useAuth, useAxios } from "../../hooks";
import axios from "axios";
import Loading from "../../components/Loading";
import { generateRegisterPDF } from "../../helpers/RegisterPDF";
import { generateRegisterPDFPensionista } from "../../helpers/RegisterPDF_Pensionista";
import Modal from "../../components/Modal";
import { useNavigate } from "react-router-dom";
import { Config } from "../../config";

export default function Home() {
  const { token } = useAuth();
  const [modal, setModal] = useState({message: '', type: '', active: false});
  const [selectedTab, setSelectedTab] = useState('sind_ativo');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState<any>();
  const [search, setSearchData] = useState('');

  const {
    response: getDataResponse,
    loading: getDataLoading,
    error: getDataError,
  } = useAxios({
    url: Config.API.Dash,
    method: "GET",
    token: token,
  });


  function onChangeSearch(value: string) {
    setSearchData(value);
  }

  async function makeRequestByTab(status: number) {
    if (selectedTab.includes('sind')) {
      const response = await axios.post(`${Config.API.Sindicalizados}/filter`, {
        filters: [
          {key: 'Nome', value: search.trim()},
          {key: 'Status_Sint', value: status}
        ]
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      const { data } = response;
      setData(data?.results);
      setTotalPages(data?.pages_total);
      setTotalItems(data?.total);
      setIsLoading(false);
    } else {
      const response = await axios.post(`${Config.API.SindicalizadosPensionistas}/filter`, {
        filters: [
          {key: 'Nome', value: search.trim()},
          {key: 'Status_Sintjam', value: status}
        ]
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      const { data } = response;
      setData(data?.results);
      setTotalPages(data?.pages_total);
      setTotalItems(data?.total);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    let status = 1;
    if (selectedTab === 'sind_inativo' || selectedTab === 'pen_inativo') {
      status = 2;
    }
    setIsLoading(true);
    const timerId = setTimeout(async () => {
      await makeRequestByTab(status);
    }, 1000);
    return () => clearTimeout(timerId);

  }, [search, selectedTab]);

  useEffect(() => {
    if (modal.active) {
      setTimeout(() => {
        setModal({message: '', type: '', active: false});
      }, 3000);
    }
  }, [modal])


  const handlePageClick = (data: any) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
  };

  function handleEditAction(value: number) {
    if (selectedTab.includes('sind')) {
      navigate(`/update/${value}`);
    }else {
      navigate(`/pensionista/${value}`);
    }
  }

  function handleRegistrationAction(value: number) {
    setIsLoading(true);
    if (selectedTab.includes('sind')) {
      axios.get(`${Config.API.Sindicalizados}/${value}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          setIsLoading(false);
          generateRegisterPDF(response.data)
          setModal({message: 'PDF gerado com sucesso', type: 'success', active: true});
        })
        .catch((error) => {
          setIsLoading(false);
          setModal({message: 'Erro ao gerar PDF', type: 'error', active: true});
        });
    }else {
      axios.get(`${Config.API.SindicalizadosPensionistas}/${value}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          setIsLoading(false);
          generateRegisterPDFPensionista(response.data)
          setModal({message: 'PDF gerado com sucesso', type: 'success', active: true});
        })
        .catch((error) => {
          setIsLoading(false);
          setModal({message: 'Erro ao gerar PDF', type: 'error', active: true});
        });
    }
  }

  function handleGetDocumentAction(value: number) {
    setIsLoading(true);
    axios.get(`${Config.API.Sindicalizados}/document/${value}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      responseType: 'blob'
    })
      .then((response) => {
        setIsLoading(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'anexo.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        setModal({message: 'Anexo gerado com sucesso', type: 'success', active: true});
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response.status === 404) {
          setModal({message: 'Anexo não encontrado', type: 'error', active: true});
        
        }else {
          setModal({message: 'Erro ao gerar Anexo', type: 'error', active: true});
        }
      });
  }

  function handleInactivateAction(value: number) {
    setIsLoading(true);
    if (selectedTab.includes('sind')) {
      axios.patch(`${Config.API.Sindicalizados}/inactivate/${value}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          setIsLoading(false);
          setModal({message: 'Sucesso ao inativar usuário', type: 'success', active: true});
        }).catch((error) => {
          setIsLoading(false);
          setModal({message: 'Erro ao Inativar sindicalizado', type: 'error', active: true});
        });
    }else {
      axios.patch(`${Config.API.SindicalizadosPensionistas}/inactivate/${value}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          setIsLoading(false);
          setModal({message: 'Sucesso ao inativar usuário', type: 'success', active: true});
        }).catch((error) => {
          setIsLoading(false);
          setModal({message: 'Erro ao Inativar sindicalizado', type: 'error', active: true});
        });
    }
  }

  function handleActivateAction(value: number) {
    setIsLoading(true);
    if (selectedTab.includes('sind')) {
      axios.patch(`${Config.API.Sindicalizados}/activate/${value}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          setIsLoading(false);
          setModal({message: 'Sucesso ao ativar usuário', type: 'success', active: true});
        }).catch((error) => {
          setIsLoading(false);
          setModal({message: 'Erro ao ativar sindicalizado', type: 'error', active: true});
        });
    }else {
      axios.patch(`${Config.API.SindicalizadosPensionistas}/activate/${value}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          setIsLoading(false);
          setModal({message: 'Sucesso ao ativar usuário', type: 'success', active: true});
        }).catch((error) => {
          setIsLoading(false);
          setModal({message: 'Erro ao ativar sindicalizado', type: 'error', active: true});
        });
    }
  }

  return (
    <>
      <div className="px-8">
        {
          modal.active && <Modal message={modal.message} type={modal.type} />
        }
        <div className="mb-10">
          <h1 className="font-semibold text-xl mb-3">Home</h1>
          <p className="text-gray-400 font-light text-base">Contagem de sindicalizados</p>
        </div>
        <div className="flex mb-20">
          <MembersCount data={getDataResponse?.data} />
        </div>
        <Card.CardContent className="relative">
          {
            isLoading && <Loading />
          }
          <Card.Header>
            <h2 className="font-semibold text-xl">Lista de Sindicalizados</h2>
            <p className="text-gray-400 font-light text-base">Contagem de sindicalizados</p>
          </Card.Header>
          <ListMembers
            values={ data || []}
            actions={
              {
                handlePageClick,
                currentPage,
                totalPages: totalPages,
                totalItems: totalItems,
                selectedTab,
                setSelectedTab,
                filterByName: onChangeSearch,
                handleEditAction,
                handleInactivateAction,
                handleRegistrationAction,
                handleActivateAction,
                handleGetDocumentAction,
              }
            }
            />
        </Card.CardContent>
      </div>
    </>
  );
}
