import classNames from "classnames"
import { useTabsContext } from "./TabsContext"

type TabTitlesProps = {
  items: {
    id: string
    title: string
  }[]
  setSelectedTab?: React.Dispatch<React.SetStateAction<string>> // Update the type here
}


export default function Titles({ items, setSelectedTab }: TabTitlesProps) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { currentIndex, setCurrentIndex } = useTabsContext()

  return (
    <div role="tablist" className="tab-titles">
      {items.map(({ id, title }, index) => (
        <button
          key={id}
          id={`tab-control-${id}`}
          role="tab"
          aria-controls={`tab-content-${id}`}
          aria-selected={currentIndex === index}
          onClick={() => {
            setCurrentIndex(index)
            setSelectedTab && setSelectedTab(id)
          }}
          type="button"
          className={classNames('tab-title', currentIndex === index && 'tab-title--active' )}
        >
          {title}
        </button>
      ))}
    </div>
  )
}