export function generateMatricula() {
  const timestamp = new Date().getTime();

  const randomDigits = (timestamp % 100).toString().padStart(2, '0');

  const randomLetter = String.fromCharCode(((timestamp / 100) % 26) + 65);

  const finalDigit = ((timestamp / 1000) % 10).toFixed(0);

  const hash = `${timestamp.toString().substr(-3)}.${randomDigits}${randomLetter}${finalDigit}-${randomLetter}S`;

  return hash;
}

